export default {
    'AddToken': 'Add Token',
    'QueryTokenId': 'enter a tokenId',
    'TokenId': 'tokenId',
    'Token':'token',
    'Description': 'description',
    'ExpireTime': 'expireTime',
    'CreateTime': 'createTime',
    'Operation': 'operation',
    'Username': 'username',
    'Operator': 'operator',
    'SevenDay':'7 days',
    'ThirtyDay':'30 days',
    'NinetyDay':'90 days',
    'OneYear': '1 year',
    'Never':'no expiration',
    'Close':'Close',
    'DescriptionPlaceholder':'please input token description',
    'ExpireTimePlaceholder':'please select token expire time',
    'UsernamePlaceholder':'please select a user',
    'NewTokenWarning':'New token has been created. Make sure you copy it now, you won\'t be able to see it again!',
    'Administrator': 'Administrator',
    'OrdinaryUser': 'Ordinary user',

}
