export default {
    "PolicyId": "策略编号",
    "PolicyName": "策略名",
    "IsEnabled": "是否启用联邦晋级策略",
    "CreatedTime": "创建时间",
    "UpdateTime": "修改时间",
    "CreatedBy": "创建人",
    "UpdateBy": "修改人",
    "SourceRepositories": "源仓库",
    "TargetRepositories": "目标仓库",
    "computing": "启用",
    "disable": "禁用",
    "CardTitle":"联邦晋级策略列表",
    "AddTitle":"新增联邦晋级策略",
    "AddPathTitle":"增加包路径规则",
    "AddMetaDataTitle":"增加源数据规则",
    "DrawerTitle":"联邦晋级策略设置",
    "PathRule":"包路径规则",
    "MetaDataRule":"元数据规则",
    "SelectSourceRepositories":"选择联邦晋级源仓库",
    "SelectNodeType":"选择目标节点类型",
    "InternalNode":"内部节点",
    "ExternalNode":"外部节点",
    "SelectingTargetNode":"选择目标节点",
    "SelectionTargetRepositories":"选择联邦晋级目标仓库",
    "PleaseInput":"请输入",
    "PleaseSelect":"请选择",
    'SelectableRepositories':'可选择的仓库',
    'SelectedRepositories':'已选择的仓库',
    'OperationSuccessful': '操作成功',
    'OperationFailure':'操作失败',
    'IsDeleteSync':'是否开启删除同步',
    "StorageId": "存储空间",
    "RepositoryId": "仓库名称",
    "Total": "个",

    
}