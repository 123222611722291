export default {
    'CustomLayoutList': 'Custom layout list',
    'YES': 'YES',
    'NO': 'NO',
    'Total': 'Total',
    'LayoutName': 'layout name',
    'ArtifactPathPattern':'Artifact path pattern',
    'CustomLayoutSetting':'Custom layout setting',
    'EnterLayoutName':'Please enter the layout name',
    'EnterArtifactPathPattern':'Please enter the artifact path pattern',
    'EnterCustomLayoutNameQuery': 'Enter layout name query',
    'Operate': 'Operation',
    'OperationSuccessful': 'Operation successful',
}