export default {
    // Storages.vue
    'StorageList': '存储列表',
    's3Storage': 's3存储',
    'CopyStorageSpacePath': '复制存储空间路径',
    'ModifyStorageSpace': '修改存储空间',
    'createModal': '新 建',
    'CreateStorageSpace': '新建存储空间',
    'CreateRepository':'新建制品仓库',
    'StorageSpaceName': '存储空间名称',
    'StorageType': '存储类型',
    'SyncStorage': '同步存储空间',
    'SyncRepository': '同步仓库',
    'LocalStorage': '本地存储',
    'Note': '说明',
    'NFSStorage': '默认为本地存储即:NFS本地目录存储',
    'BucketNameDefinition': 'S3存储：默认以存储空间名称作为桶名,您也可以自定义桶名称',
    'unmodifiableNote': '注意：存储空间名称、存储类型、S3存储桶路径，一旦创建不可修改',
    'LocalPath': '本地路径',
    'S3Path': 'S3路径',
    'BucketName': '桶名称',
    'ParentDirectory': '父目录',
    'Custom': '自定义',
    'cancelCustom': '取消自定义',
    'Administrator': '管理员选择',
    'SelectAdministrator': '请选择管理员',
    'user': '用户成员选择',
    'selectUser': '请选择用户',
    'Cancel': '取消',
    'Confirm': '确定',
    'StorageSpaceOperation': '修改或删除存储空间',
    'SpaceNameRemain': '存储空间名称不允许修改',
    'BucketRemain': '存储类型、S3类型的桶均不允许修改',
    'ImportantNote': '说明（请谨慎操作！！！）',
    'HaveAccess': '你选择的管理员/成员列表将拥有该存储空间的使用权限',
    'RegularCleaning': '删除:只删除存储配置，每日0点会定时清理',
    'IfForcedDeletion': '若强制删除则无法恢复仓库列表',
    'Edit': '修改',
    'Delete': '删除',
    'create': '新建',
    'ForcedDeletion': '强制删除',
    'ConfirmDeletion1': '你确定要删除',
    'ConfirmDeletion2': '这个制品仓库么？请谨慎操作',
    'WarehouseName': '仓库名称',
    'LogicalDeletion': '删除:只逻辑删除，不删除安装包',
    'ProductWarehouse': '制品库',
    'Start': '开始',
    'yourFolib': '你的制品库',
    'Will': '将会在',
    'UnderStorageSpace': '存储空间下',
    'TypeSelection': '类型选择',
    'BasicInformation': '基础信息',
    'RemoteConfiguration': '远程配置',
    'CombinationConfiguration': '组合配置',
    'ClickNext': '不可修改，请点击下一步',
    'HowToChoose': '不知道怎么选择',
    'WarehouseTypeRemain': '修改模式下不可以更换仓库类型！',
    'IdentifyWarehouseType': '根据图标以及下发的类型名称进行识别，找到你要选择的仓库类型吧！',
    'NextVersion': '下一个版本更新即将呈现',
    'Next': '下一步',
    'FillInInformation': 'OK,接下来要填写基础信息',
    'DockerType': '你选择的是Docker仓库类型',
    'LayoutType': '你选择的是{layout}仓库类型',
    'DifferentProcess': '选择不同的仓库策略要配置的流程不太一样',
    'KeywordPrompt': '不要出现仓库类型的关键字',
    'Strategy': '策略',
    'Local': '本地',
    'Agent': '代理',
    'Combination': '组合',
    'VersioningStrategy': '版本策略',
    'WarehousePath': '仓库路径',
    'EnableCustomLayout': '启用自定义布局',
    'CustomLayout': '自定义布局',
    'CustomLayoutTip': '请选择您需要自定义布局',
    'DistributedRemain': '当前存储为分布式，不支持存储路径定义',
    'ItemLimit': '制品大小限制(MB)',
    'RepositorySizeLimit': '仓库存储限制(GB)',
    'StorageSizeLimit': '存储限制(TB)',
    'ServiceStatus': '服务状态',
    'OpenService': '开放服务',
    'ShutdownService': '关闭服务',
    'RecycleBin': '回收站',
    'UploadDeploy': '上传部署',
    'UploadOverlay': '上传覆盖',
    'DirectoryBrowsing': '目录浏览',
    'Allowed': '允许',
    'NotAllowed': '不允许',
    'Back': '回退',
    'Complete': '完成',
    'WarehouseConfig': '远程仓库配置',
    'RemoteLibraryAddress': '您选择的是远程仓库类型，还需要配置一下你的远程库地址，也可以开启本地代理访问远程地址',
    'RemoteAccessAddress': '远程访问地址',
    'AddressFormat': 'http://xxxx或者https://xxxx',
    'Test': '测试',
    'Username': '用户名',
    'AccessUser': '远程仓库访问用户名',
    'AccessPassword': '远程仓库访问密码',
    'TimedCheck': '定时检查时间(秒)',
    'DefaultTime': '默认60秒',
    'InspectionMechanism': '检查机制',
    'None': '无',
    'Strict': '严格',
    'LogRecording': '日志记录',
    'Advanced': '高级',
    'LocalAgent': '本地代理',
    'On': '开启',
    'Off': '不开启',
    'AutomaticLock': '自动封锁',
    'ChecksumCheck': '校验和检查',
    'RemoteIndexDownload': '远程索引下载',
    'Download': '下载',
    'NoDownload': '不下载',
    'ProxySetup': '代理设置',
    'UseProxySettings': '只有在当前制品库无法访问远程代理库的情况下，可以使用该功能',
    'Port': '端口号',
    'Protocol': '协议',
    'NoUserName': 'proxy的用户名，没有可以不填写',
    'Password': '密码',
    'NoPassword': 'proxy的用户密码，没有可以不填写',
    'CompositeWarehouse': '组合仓库配置',
    'WarehousePortfolio': '你选择的是组合仓库，可以将多个仓库从左至右进行拖动，进行组合.',
    'CustomizedStrategy': '给仓库配置定制策略',
    'TimingStrategy': '定时策略用来设定仓库垃圾清理，同步等相关策略',
    'HaveSet': '已设定',
    'ExpandSetting': '展开设定',
    'ExecuteOnce': '执行一次',
    'LoopExecution': '循环执行',
    'ImmediateExecution': '立即执行',
    'NoImmediateExecution': '不立即执行',
    'OtherParameters': '其他参数',
    'CompleteSetting': '完成策略设定',
    'EnterSpaceName': '请输入存储空间名称',
    'SpaceNameLengthLimit': '长度在 1 到 30 个字符',
    'SpaceNameLimit': '存储空间名称应为大小写字母，数字，特殊符号(-_.)',
    'SelectableRepository': '可选择制品仓库',
    'CompositeRepository': '已组合仓库',
    'LicenseError': 'License不正确',
    'CheckLicense': '请检查License是否存在',
    'LicenseExpired': 'License已过期',
    'AddWarehouseAfterRenewal': '请续期后再添加制品仓库',
    'CopySuccessful': '复制成功',
    'UnknownError': '未知错误',
    'StorageNameExists': '存储空间名称已存在',
    'FailedCreate': '创建失败',
    'OperationIncorrect': '操作不正确',
    'selectSpace': '你应该先从左侧选中一个存储空间,然后再新建仓库',
    'Success': '成功',
    'FillInCronExpression': '请填写cron表达式',
    'Failure': '失败',
    'FillInErrors': '填写错误',
    'EnterRepository': '请输入仓库名称',
    'RepositoryLengthLimit': '仓库名称长度在 1 到 30 个字符',
    'RepositoryLimit': '仓库名称应为大小写字母，数字，特殊符号(-_.)，不能以_.开头',
    'WarehouseModified': '仓库已修改完成',
    'WarehouseAdded': '仓库已新增完成',
    'RepositoryNameExists': '仓库名称已存在',
    'Deleted': '已删除',
    'ContentError': '要删除的内容填写错误',
    'ConnectedFine': '远程仓库连接正常',
    'ConnectedFailed': '远程仓库连接失败',
    'ResponseStatusCode': '响应状态码',
    'warehouseTips': ' （tips: 某些仓库不支持浏览也会返回404，但不影响构建使用，请检查该远程仓库是否为此类仓库）',
    'WarehouseSetup': '仓库设置',
    'PermissionSetting': '权限设置',
    'TimingPolicy': '定时策略',
    'FederatedRepository': '联邦仓库',
    'Scan': '扫描',
    // LibView.vue
    'Details': '详情',
    'Statistics': '统计',
    'MakingHistory': '制作历史',
    'ProductPath': '制品路径',
    'CreationTime': '创建时间',
    'LastTimeOfUse': '最近使用时间',
    'NumberOfDownloads': '下载次数',
    'ProductSize': '制品大小',
    'CVENumber': 'CVE编号',
    'VulnerabilityLevel': '漏洞等级',
    'CvssV2VulnerabilityLevel': 'CvssV2漏洞等级',
    'CvssV3VulnerabilityLevel': 'CvssV3漏洞等级',
    'CvssV2Score': 'CvssV2评分',
    'CvssV3Score': 'CvssV3评分',
    'ImportVersion': '引入版本',
    'VulnerabilityNumber': '漏洞编号',
    'IntroducingTime': '引入时间',
    'HighestVulnerabilityLevel': '最高漏洞等级',
    'RecommendedFixVersion': '建议修复版本',
    'Operation': '操作',
    'TheNumbers': '数字',
    'String': '字符串',
    'Text': '文本',
    'OperationSuccessful': '操作成功',
    // Scanner.vue
    'ScannedPacketsNum': '扫描包总数',
    'InsteadOfScanningPacketsNum': '无需扫描包总数',
    'SuccessScannedPacketsNum': '扫描成功的包数量',
    'FailedScansNUM': '扫描失败的包数量',
    'ScanDependenciesNum': '扫描依赖数量',
    'VulnerablePackagesNum': '具有漏洞的包数量',
    'VulnerabilitiesTotalNum': '漏洞总数',
    'sealedVulnerabilitiesNum': '封存漏洞数量',
    'Compare': '近7天漏洞分布视图',
    'packagesNum': '较上周包数量',
    'unknown': '不变',
    'unchanged': '未知',
    'dataCompared': '以下为本周与上周(14天)的数据进行比较的结果',
    'NewVulnerabilityPackage': '漏洞包新增',
    'ScanForNewDependencies': '扫描依赖新增',
    'NewVulnerabilityDependency': '漏洞依赖新增',
    'NewSealedVulnerabilitiesNum': '封存漏洞数新增',
    '30daysData': '近30天数据',
    '30daysDataChat': '本图为近30天数据,无新增数据的日期不展示',
    'VulnerabilitiesNum': '漏洞数量',
    'WarehouseScanningSituation': '仓库扫描情况',
    'PlatformVulnerability': '平台漏洞情况',
    'DependingOnQuantity': '依赖数量',
    'Warehouse': '仓库',
    'StorageSpace': '存储空间',
    'TotalNumberOfPackets': '包总数',
    'NumberOfQuestions': '问题包数',
    'SecurityScore': '安全评分',
    'EventRecord':'事件记录',
    'PromotionRecord':'晋级记录',
    'GitRepositoryAddr': 'git仓库地址',
    'GitProviderCredential': 'git私有仓库凭据',
    'GitUsername': 'git仓库用户名',
    'GitPassword': 'git仓库密码/token',
    'GitProvider': 'Git Provider',
    'AddGitCredential': '添加git凭据',
    'StorageOverview': '存储概览',
    'FilesSize':'文件大小',
    'FilesCount':'文件数量',
    'RepositoryCount': '仓库数量',
    'ArtifactsSize':'制品大小',
    'ArtifactsCount':'制品数量',
    'FoldersCount':'文件夹数量',
    'ItemsCount':'条目数量',
    'StorageDeviceType': '存储设备类型',
    'StorageQuotaSize':'存储空间配额',
    'DataUpdateTime':'数据更新时间',
    'LastTenUpdates': '近10次更新存储数据视图',
    'RepositoryList': '仓库列表',
    'ScanTime': '扫描时间',
    'RepositoryQuery': '请选择仓库查询',
    'RepositoryInfo':'仓库信息',
    'packageTypeQuery':'选择包类型搜索',
    'StrategyTypeQuery': '选择策略类型搜索',
    'RepositoryNameQuery':'输入仓库名称搜索',
    'Loading':'加载更多',
    'Save':'保存',
    'raw':'Raw（也叫 Generic）是一种通用的类型，可用于存储如文档、SQL脚本、环境配置信息等各类制品。Generic 仓库还可用于成品包的管理，将成品包发布至仓库，并可按企业业务规则（如系统-子系统-版本-发版文件、发版配置、发版说明）创建层级目录，更便于对成品包的业务管理。',
    'generic': 'Generic是一种通用的类型，可用于存储如文档、SQL脚本、环境配置信息等各类制品。Generic 仓库还可用于成品包的管理，将成品包发布至仓库，并可按企业业务规则（如系统-子系统-版本-发版文件、发版配置、发版说明）创建层级目录，更便于对成品包的业务管理。',
    'gitlfs':'Git LFS（Large File Storage）是 Git 的扩展，Git LFS 将代码仓库中的大文件替换为指针（pointer）文件，指针文件中声明大文件的真实存储信息。本仓库支持本地、代理、组合，用于存储 Git 仓库中的大文件，通过 .lfsconfig 文件声明仓库信息，并通过跟踪文件，在提交时即可将大文件发布至仓库。在使用过程中请查看仓库指引进行操作。',
    'cocoapods':'在进行 iOS 项目开发时需要使用第三方开源库，CocoaPods 是一个帮助我们管理第三方依赖库的工具，它可以解决库与库之间的依赖关系。本仓库支持开源 cocoapods-art 插件客户端，可通过 Folib 仓库统一管理依赖并可提高依赖拉取速度。具体使用方法可创建后查看仓库使用帮助。',
    'php':'Composer 是 PHP 用来管理依赖（dependency）关系的工具，你可以在自己的项目中声明所依赖的外部工具库（libraries），Composer 会帮你安装这些依赖的库文件。Composer 仓库则用于存储 PHP 依赖（dependency）。',
    'debian':'Debian GNU/Linux 是一个操作系统及自由软件的发行版，apt 是基于 Debian 的 Linux 系统上管理软件包的 CLI 工具。Debian 仓库可作为 Debian 操作系统的软件包中心，按 Debian 官方规则存储软件包，在使用中通过 APT 来拉取软件包。',
    'docker':'Docker 是开源化容器化平台，开发者可通过 Docker 打包应用程序以及其依赖项成为一个轻量级、可移植的容器。Docker 仓库可代理 Docker Hub 等镜像中心，本地仓库用于存储 Docker 客户端构建后的容器镜像，也可虚拟组合使用。支持 buildx 多架构、在线上传 tar 包自动解压识别等功能。',
    'conan':'Conan 是面向 C 和 C++ 开发人员的软件包管理器，通过 Conan 仓库可存储 C、C++ 项目中所需的依赖包以及二进制包，可进行代理 Conan 外部源、本地上传以及虚拟组合使用。',
    'go':'Go 仓库可作为 Golang 的依赖仓库，通过配置镜像源支持对 Go 依赖的拉取代理、本地上传、虚拟组合使用。',
    'gradle':'Gradle 是一个先进的构建自动化工具，被设计用来高效地构建和管理各种规模的项目，特别是 Java、Android 项目。Gradle 仓库可代理 Java、Android、Kotlin、Groovy、Scala 依赖仓库，本地仓库则用于存储 Gradle 构建后生成的项目包，也可虚拟组合使用。',
    'helm':'Helm 使用 Charts 的模板文件来定义 Kubernetes 应用程序，Charts 中包含了用于创建 Kubernetes 资源（如 Deployment、Service、ConfigMap 等）的所有必要信息。Helm 仓库可代理 Helm 官方仓库等 Charts 仓库，本地仓库则用于存储 Helm Charts 包。',
    'maven':'Apache Maven 是一个 Java 项目管理和理解工具，它包含了一个项目对象模型（POM）、一套规则和对项目依赖关系的管理，以及将定义用于构建项目的一系列绑定目标的构建过程。Maven 仓库可代理 Maven 中央仓库等依赖仓库，本地仓库则用于存储 Maven 构建后生成的项目 Jar 包，支持在线批量上传自动识别坐标。',
    'npm':'Npm 是 Node.js 官方提供的包管理工具，用于 Node.js 包的发布、传播以及依赖控制。Npm 仓库可代理 Npm 官方仓库等依赖仓库，本地仓库则用于存储 Npm 构建后生成的项目包。',
    'nuget':'NuGet 是 .NET Framework 和 .NET Core/Standard 平台上的一种开源包管理系统，旨在简化在 .NET 应用程序中使用、创建和管理库的过程。NuGet 仓库主要用于管理 C#、VB.NET 和其他 .NET 相关语言的项目依赖，可代理 NuGet 官方仓库等依赖仓库，支持本地存储发布生成的项目包。',
    'ohpm':'OHPM 是鸿蒙 OpenHarmony 三方库的包管理工具，支持 OpenHarmony 共享包的发布、安装和依赖管理。OHPM 仓库与 OHPM 包管理器兼容，远程仓库可代理 OpenHarmony 三方库中心并按需缓存所有依赖项，本地仓库可作为私有依赖仓库，存储鸿蒙开发中的私有依赖。',
    'pub':'Pub 是 Dart 语言中用于管理和发布代码包的官方工具，Flutter 框架是一款流行的多平台 UI 工具包，由 Dart 语言强力驱动。Pub 仓库可用于管理 Dart、Flutter 语言依赖。',
    'pypi':'PyPI (Python Package Index) 是 Python 社区的一个核心组成部分，提供 Python 开发者获取第三方库的主要来源。PyPI 仓库可代理 PyPI 官方仓库等依赖仓库，本地仓库则用于存储 PyPI 发布生成的项目包。',
    'rpm':'RPM（Red Hat Package Manager）主要用于基于红帽的操作系统（如 Fedora、CentOS、RHEL 等）上的包管理工具，提供了软件包的安装、卸载、升级、查询和验证的能力。Rpm 仓库提供面向 RPM 的包发布与拉取能力。',
    'rust':'在 Rust 生态系统中，Cargo 是官方的构建系统和包管理器，支持依赖管理与构建自动化。Cargo 仓库可代理并缓存 Rust 官方依赖提高拉取速度，并可创建本地仓库存储私有 Rust 依赖或者构建后的制品包。',
    'ivy':'Apache Ivy 是一个灵活的依赖管理工具，常用于 Java 项目中。Ivy 仓库可代理外部 Ivy 依赖源，并支持本地上传以及虚拟组合使用，适用于存储和管理复杂依赖关系的项目库。',
    'sbt':'SBT（Simple Build Tool）是 Scala 项目最流行的构建工具，可管理项目依赖并实现构建自动化。SBT 仓库可代理外部 SBT 仓库源，支持本地上传生成的项目包，以及虚拟组合使用。',
    'yarn':'Yarn 是一个快速、安全且可靠的 JavaScript 包管理工具。Yarn 仓库可代理 npm或其他Yarn 官方仓库，支持本地上传和虚拟组合使用，为团队协作提供高效的依赖管理解决方案。',
    'huggingface':'Hugging Face 是目前广泛使用的自然语言处理与机器学习工具库，其仓库支持存储和代理 Hugging Face 官方模型及数据集，提供本地上传自定义模型的功能，并支持虚拟组合操作，满足 AI 项目中对模型和数据集的版本化与分发需求。',
    'gems':'RubyGems 是 Ruby 编程语言的包管理系统，负责管理 Gem（Ruby 中的软件包）的安装、分发和依赖解析。Gems 仓库可代理外部 Gem 源（如 RubyGems.org），支持本地上传与存储私有 Gem 包，同时提供虚拟组合功能，方便团队协作与 Gem 包的统一管理。',
    'conda':'Conda 是一个快速、跨平台的开源包管理和环境管理系统，常用于数据科学、机器学习和 AI 开发中。Conda 仓库可用于管理 Python 和其他语言的依赖包，支持代理 Anaconda 和 Miniconda 源，并可本地存储和分发自定义环境。',
    'opkg':'Opkg（Open PacKaGe Management）是一个轻量级的包管理工具，专门为嵌入式 Linux 系统设计，例如 OpenWrt 和 Yocto 项目。Opkg 仓库可用于管理嵌入式设备所需的软件包及其依赖关系，支持本地上传与代理外部源。',
    'swift':'Swift 是 Apple 推出的现代编程语言，用于开发 iOS 和 macOS 应用。Swift Package Manager（SPM）是 Swift 的官方包管理工具。Swift 仓库支持存储和分发 SPM 包，可代理外部 Swift 包源并按需缓存，支持本地上传与虚拟组合使用，满足开发团队对依赖管理和分发的需求。',
    'cargo':'在 Rust 生态系统中，Cargo 是官方的构建系统和包管理器，支持依赖管理与构建自动化。Cargo 仓库可代理并缓存 Rust 官方依赖提高拉取速度，并可创建本地仓库存储私有 Rust 依赖或者构建后的制品包。',
    // 'rust':'rust',
    // 'php':'php',
    
    'StorageThreshold': '存储阈值',
}
