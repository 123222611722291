export default {
    'StrategyList': 'Strategy List',
    'YES': 'YES',
    'NO': 'NO',
    'Total': 'Total',
    'StorageId': 'StorageId',
    'RepositoryId': 'RepositoryId',
    'StrategyName': 'Backup strategy name',
    'Repositories': 'Repositories',
    'CronExpression':'Cron Expression',
    'Enabled':'Enabled',
    'BackupStrategySetting':'Backup strategy setting',
    'EnterStrategyName':'Please enter the backup strategy name',
    'ChooseBackupRepository':'Choose to backup the repository',
    'SelectableRepositories':'Selectable repositories',
    'SelectedRepositories':'Selected repositories',
    'EnterCronExpression':'Please enter the cron expression',
    'BackupPath':'Backup path',
    'Incremental':'Incremental',
    'IncrementalTip':'If this setting is enabled, an incremental directory will be created in the backup directory, and all artifacts will be backed up to this directory during the first execution. The subsequent execution is incremental, and newly added and modified artifacts will be written to this directory. If this setting is turned off, it will be in full backup mode, and each backup will create a full backup directory in the backup directory according to the backup time.',
    'RetentionPeriod':'Retention Period Days',
    'RetentionPeriodTip':'If the backup mode is full backup, every time a backup is performed, a full backup directory will be created in the backup directory according to the backup time. This setting refers to the retention period of historical full backup directory data, with a default value of 7 days',
    'ChooseBackupPath':'Choose to backup the directory',
    'EnterBackupStrategyNameQuery': 'Enter backup name query',
    'EnterStorageIdQuery': 'Enter storage query',
    'EnterRepositoryIdQuery': 'Enter repository query',
    'Operate': 'Operation',
    'OperationSuccessful': 'Operation successful',
}