export default {
    // Artifacts.vue
    'ArtifactPathQuery': '输入制品路径查询',
    'StorageSpace': '存储空间',
    'OwnedWarehouse': '所属仓库',
    'ProductPath': '制品路径',
    'LastUsedTime': '最近使用时间',
    'DownloadTimes': '下载次数',
    'ProductSize': '制品大小',
    'Vulnerability': '漏洞',
    'RepositorySelect': '请选择仓库查询',
    // index.vue
    'GeneralView': '总览',
    'Component': '组件',
    // ArtifactDashboard.vue
    'OwningSpace': '所属空间',
    'FileSize': '文件大小',
    'ModifyTheTime': '修改时间',
    'ServiceTime': '使用时间',
    'ImageName': '镜像名称',
    'VersionNumber': '版本号',
    'NumberOfFloors': '层数',
    'MakeADockerVersion': '制作Docker版本',
    'MirrorOS': '镜像OS',
    'TheInfrastructure': '基础架构',
    'Total': '总数',
    'Seriously': '严重',
    'MediumRisk': '中危',
    'HighRisk': '高危',
    'LowRisk': '低危',
    // ArtifactComponents.vue
    'componentNameQuery': '输入组件名称查询',
    'ComponentName': '组件名称',
    'Group': '组',
    'Licence': '许可证',
    // ArtifactVulnerability.vue
    'EnterVulnerabilityNumber': '输入漏洞编号查询',
    'VulnerabilityDescription': '漏洞描述',
    'VulnerabilityLevel': '漏洞等级',
    'CvssV2VulnerabilityLevel': 'CvssV2漏洞等级',
    'CvssV3VulnerabilityLevel': 'CvssV3漏洞等级',
    'CvssV2Score': 'CvssV2评分',
    'CvssV3Score': 'CvssV3评分',
    'ImportVersion': '引入版本',
    'VulnerabilityNumber': '漏洞编号',
    'IntroducingTime': '引入时间',
    'HighestVulnerabilityLevel': '最高漏洞等级',
    'RecommendedFixVersion': '建议修复版本',
    // ChartArtifactComponent.vue
    'ComponentStatistics': '组件统计',
    'ComponentClassification': '组件分类',
    'TotalNumberOfComponents': '组件总数',
    'HealthyComponents': '健康组件',
    'RiskComponents': '风险组件',
    'ComponentInformation': '组件信息',
    'ComponentOverview': '组件概览',
    'ScanTime': '扫描时间',
    'exportExcel':'导出execl',
    'exportPdf':'导出PDF',
    'printExportCount':'请输入导出数量',
    'OK':'确定',
    'CANCEL':'取消',
    'exporting':'正在导出...',
    'ProductScanDetail':'制品扫描详情',
    'ProductScan':'制品扫描'

}
