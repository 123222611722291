export default {
    'UserManagement': 'User management',
    'Adduser': 'Add a user',
    'TotalNumberOfUsers': 'Total number of users',
    'NumberOfAdministrators': 'Number of administrators',
    'NumberOfAnonymousUsers': 'Number of anonymous users',
    'UserList': 'User list',
    'EnterTheUsernameQuery': 'Enter the username query',
    'EnterTheEmailQuery': 'Enter the email query',
    'EnterTheNicknameQuery': 'Enter the nickname query',
    'IsItAvailable': 'Is it available',
    'Available': 'Available',
    'NotAvailable': 'Not available',
    'UserInformation': 'User information',
    'UserEdit': 'User edit',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'UserName': 'User name',
    'EnterYourUsername': 'Please enter your username',
    'Password': 'Password',
    'PleaseEnterEmail': 'Please enter Email, not required',
    'ActivatedOrNot': 'Activated or not',
    'TurnOn': 'On',
    'ShutDown': 'Off',
    'RoleInformation': 'Role information',
    'RoleEditing': 'Role editing',
    'DeleteConfirmation': 'Delete confirmation',
    'SureDeleteUser1': 'Are you sure you want to delete user ',
    'SureDeleteUser2': '?',
    'Delete': 'Delete',
    'userNameLength': 'Between 2 and 36 characters in length',
    'EnterThePassword': 'Please enter the password',
    'passwordLength': 'Between 12 and 30 characters in length',
    'PleaseSelectTheRole': 'Please select the role',
    'DeleteSuccess': 'Delete successfully',
    'PasswordFormat': 'Passwords should be letters, numbers, special symbols (~! @ # $% ^ & * () _.) , three or more combinations, a string of 12 to 30 characters, such as example666@abc',
    // Personal.vue
    'PersonalInformation': 'Personal information',
    'Email': 'Email',
    'NewPassword': 'New password',
    'EnterNewPassword': 'Please enter a new password',
    'EnterNewPasswordAgain': 'Enter the new password again',
    'EnterYourNewPasswordAgain': 'Please enter your new password again',
    'TwoDifferentPasswordInputs': 'Two different password inputs',
    'DuplicatePasswordsCannotBeEmpty': 'Duplicate passwords cannot be empty',
    'LogInAgain': 'Personal information and password have been updated successfully. Please log in again',
    'InformationUpdated': 'Personal information is updated successfully',
    'FailedUpdateInformation': 'Failed to update personal information',
    'EnterEmail': 'Please enter Email',
    'OperateSuccess': 'Operation successful',
    'PasswordUpdateFailed': 'Password update failed',
    'PasswordUpdateSuccess': 'The password was updated successfully. Please log in again',
    'GroupInformation': 'Group Information',
    'GroupEdit': 'Group Edit',
    'UserRole':'Please select a user role',
    'Administrators': 'Administrators',
    'GeneralUsers': 'General users',
    'Nickname': 'nickname',
    'PleaseEnterNickname': 'Please enter Nickname, not required',
}
