var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"left_tree_container"},[(_vm.isDragging)?_c('div',{staticClass:"cover-box"}):_vm._e(),_c('div',{ref:"tree_container_sty",staticClass:"tree_container_sty",style:({ height: _vm.topHeight + 'px' })},[_c('a-tree',{staticClass:"leftTree",attrs:{"replaceFields":{
                key: 'artifactPath',
                title: 'name',
                children: 'children',
            },"tree-data":_vm.treeData,"load-data":function (treeNode) { return _vm.onLoadData(treeNode,false); },"selectedKeys":_vm.selectedKeys,"expandedKeys":_vm.expandedKeys},on:{"select":function (key,e){ return _vm.treeSelect(key,e,false); },"rightClick":_vm.onRightClick,"expand":function (expandedKeys, treeNode) { return _vm.onExpand(expandedKeys, treeNode, false); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
            var expanded = ref.expanded;
            var name = ref.name;
            var id = ref.id;
            var type = ref.type;
            var selected = ref.selected;
            var fileType = ref.fileType;
return [_c('div',{staticClass:"title_box"},[_c('span',[(type === 'dir' || type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"10px"},attrs:{"type":_vm.getIconType(name, type)}}),_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(name)+" ")])],1)])]}}])},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"}),_c('a-icon',{attrs:{"slot":"switcherIcon","type":"folder-open"},slot:"switcherIcon"})],1)],1),_c('div',{staticClass:"line-box",class:_vm.isDragging ? 'line-drag' : '',on:{"mousedown":_vm.startDragging}}),_c('div',{ref:"tree_container_sty",staticClass:"tree_container_sty recycle",style:({ height: _vm.bottomHeight + 'px' })},[_c('a-tree',{staticClass:"leftTree",attrs:{"replaceFields":{
                key: 'artifactPath',
                title: 'name',
                children: 'children',
            },"tree-data":_vm.trashData,"load-data":function (treeNode) { return _vm.onLoadData(treeNode,true); },"selectedKeys":_vm.selectRecycleKeys,"expandedKeys":_vm.expandedRecycleKeys},on:{"select":function (key,e){ return _vm.treeSelect(key,e,true); },"rightClick":_vm.onRightClick,"expand":function (expandedKeys, treeNode) { return _vm.onExpand(expandedKeys, treeNode, true); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
            var expanded = ref.expanded;
            var name = ref.name;
            var id = ref.id;
            var type = ref.type;
            var selected = ref.selected;
            var fileType = ref.fileType;
            var title = ref.title;
return [_c('div',{staticClass:"title_box"},[_c('span',[(type === 'dir' || type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"8px"},style:(type == 'recycle'? 'color:#393b3e':''),attrs:{"type":_vm.getIconType(name, type)}}),_c('span',{staticClass:"tree_title",style:(type == 'recycle'? 'color:#393b3e':'')},[_vm._v(" "+_vm._s(title || name)+" ")])],1)])]}}])},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"}),_c('a-icon',{attrs:{"slot":"switcherIcon","type":"folder-open"},slot:"switcherIcon"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }