export default {
    // Storages.vue
    'StorageList': 'Storage List',
    's3Storage': 's3 storage',
    'CopyStorageSpacePath': 'Copy storage space path',
    'ModifyStorageSpace': 'Modify storage space',
    'createModal': 'create',
    'create': 'create ',
    'CreateStorageSpace': 'Create storage space',
    'CreateRepository':'Create Repository',
    'StorageSpaceName': 'Storage space name',
    'StorageType': 'Storage type',
    'SyncStorage': 'Sync Storage',
    'SyncRepository': 'Sync Repository',
    'LocalStorage': 'Local storage',
    'Note': 'Note',
    'NFSStorage': 'The default storage is NFS local directory storage.',
    'BucketNameDefinition': 'S3 Storage: The default bucket name is the storage space name. You can also customize the bucket name.',
    'unmodifiableNote': 'Note: The storage space name, storage type, and S3 bucket path cannot be modified once created.',
    'LocalPath': 'Local path',
    'S3Path': 'S3 path',
    'BucketName': 'Bucket name',
    'ParentDirectory': 'Parent directory',
    'Custom': 'Custom',
    'cancelCustom': 'cancel custom',
    'Administrator': 'Administrator',
    'SelectAdministrator': 'Please select Administrator',
    'user': 'user',
    'selectUser': 'Please select User',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'StorageSpaceOperation': 'Modify or delete storage space',
    'SpaceNameRemain': 'The storage space name cannot be modified.',
    'BucketRemain': 'The storage type and S3 bucket cannot be changed.',
    'ImportantNote': 'Note (Please exercise caution!!)',
    'HaveAccess': 'The administrator/member list you select will have access to the storage space.',
    'RegularCleaning': 'Delete: Only storage configurations are deleted. The storage configurations are deleted periodically at 00:00 every day.',
    'IfForcedDeletion': 'The repository list cannot be recovered if it is forcibly deleted.',
    'Edit': 'Edit',
    'Delete': 'Delete',
    'ForcedDeletion': 'Forced deletion',
    'ConfirmDeletion1': 'Are you sure you want to delete the ',
    'ConfirmDeletion2': ' product warehouse? Please proceed with caution.',
    'WarehouseName': 'Warehouse name',
    'LogicalDeletion': 'Delete: Only the installation package is deleted logically.',
    'ProductWarehouse': 'Product warehouse',
    'Start': 'Start ',
    'yourFolib': 'your product library',
    'Will': 'A new product library will be',
    'Under': 'under',
    'StorageSpace': 'storage space',
    'TypeSelection': 'Type selection',
    'BasicInformation': 'Basic information',
    'RemoteConfiguration': 'Remote configuration',
    'CombinationConfiguration': 'Combination configuration',
    'ClickNext': 'Cannot be modified, please click Next',
    'HowToChoose': "Don't know how to choose?",
    'WarehouseTypeRemain': 'You cannot change the warehouse type in modified mode!',
    'IdentifyWarehouseType': 'Identify the type of warehouse you want to choose according to the icon and the type name issued!',
    'NextVersion': 'The next version update is coming soon',
    'Next': 'Next',
    'FillInInformation': 'OK, then fill in the basic information',
    'DockerType': 'You have selected the Docker repository type',
    'LayoutType': 'You have selected the {layout} repository type',
    'DifferentProcess': 'The process for configuring different warehouse policies is different',
    'KeywordPrompt': 'Do not appear warehouse type keywords',
    'Strategy': 'Strategy',
    'Local': 'Local',
    'Agent': 'Agent',
    'Combination': 'Combination',
    'VersioningStrategy': 'Versioning strategy',
    'WarehousePath': 'Warehouse path',
    'EnableCustomLayout': 'Enable custom layout',
    'CustomLayout': 'Custom layout',
    'CustomLayoutTip': 'Please select the layout you need to customize',
    'DistributedRemain': 'The current storage is distributed, and storage path definition is not supported',
    'ItemLimit': 'Item Size Limit (MB)',
    'RepositorySizeLimit': 'Repository Size Limit (GB)',
    'StorageSizeLimit': 'Storage Size Limit (TB)',
    'ServiceStatus': 'Service status',
    'OpenService': 'In Service',
    'ShutdownService': 'Out of Service',
    'RecycleBin': 'Recycle bin',
    'UploadDeploy': 'Upload and deploy',
    'UploadOverlay': 'Upload overlay',
    'DirectoryBrowsing': 'Directory browsing',
    'Allowed': 'Allowed',
    'NotAllowed': 'Not allowed',
    'Back': 'Back',
    'Complete': 'Complete ',
    'WarehouseConfig': 'Remote warehouse configuration',
    'RemoteLibraryAddress': 'You have selected the remote repository type, but also need to configure your remote library address, you can also open the local proxy to access the remote address',
    'RemoteAccessAddress': 'Remote access address',
    'AddressFormat': 'http://xxxx or https://xxxx',
    'Test': 'Test',
    'Username': 'Username',
    'AccessUser': 'User name for remote warehouse access',
    'AccessPassword': 'Remote warehouse access password',
    'TimedCheck': 'Timed check time (seconds)',
    'DefaultTime': 'Default 60 seconds',
    'InspectionMechanism': 'Inspection mechanism',
    'None': 'None',
    'Strict': 'Strict',
    'LogRecording': 'Log recording',
    'Advanced': 'Advanced',
    'LocalAgent': 'Local agent',
    'On': 'On',
    'Off': 'Off',
    'AutomaticLock': 'Automatic locking',
    'ChecksumCheck': 'Checksum check',
    'RemoteIndexDownload': 'Remote index download',
    'Download': 'Download',
    'NoDownload': 'Do not download',
    'ProxySetup': 'Proxy setup',
    'UseProxySettings': 'This feature can only be used if the current artifact library does not have access to the remote agent library',
    'Port': 'Port',
    'Protocol': 'Protocol',
    'NoUserName': 'proxy user name. None can be left blank',
    'Password': 'Password',
    'NoPassword': 'proxy user password. No parameter can be left blank',
    'CompositeWarehouse': 'Composite warehouse configuration',
    'WarehousePortfolio': 'You have selected a combination warehouse, which can be dragged from left to right to combine multiple warehouses.',
    'CustomizedStrategy': 'Configure custom policies for the warehouse',
    'TimingStrategy': 'The timing policy is used to set the related policies such as warehouse garbage cleaning and synchronization',
    'HaveSet': 'Have set',
    'ExpandSetting': 'Expand setting',
    'ExecuteOnce': 'Execute once',
    'LoopExecution': 'Loop execution',
    'ImmediateExecution': 'Immediate execution',
    'NoImmediateExecution': 'No immediate execution',
    'OtherParameters': 'Other parameters',
    'CompleteSetting': 'Complete policy setting',
    'EnterSpaceName': 'Please enter the storage space name',
    'SpaceNameLengthLimit': 'Length between 1 and 30 characters',
    'SpaceNameLimit': 'The storage space name should consist of uppercase and lowercase letters, numbers, and special characters (-.)',
    'SelectableRepository': 'Selectable artifact repository',
    'CompositeRepository': 'Composite Repository',
    'LicenseError': 'The License is incorrect',
    'CheckLicense': 'Please check if the License exists',
    'LicenseExpired': 'The License has expired',
    'AddWarehouseAfterRenewal': 'Please add the product warehouse after renewal',
    'CopySuccessful': 'Copy successfully',
    'UnknownError': 'Unknown error',
    'StorageNameExists': 'The storage name already exists',
    'FailedCreate': 'Failed to create',
    'OperationIncorrect': 'The operation is incorrect',
    'selectSpace': 'You should first select a storage space from the left and then create a new repository',
    'Success': 'Success',
    'FillInCronExpression': 'Please fill in the cron expression',
    'Failure': 'Failure',
    'FillInErrors': 'Fill in errors',
    'EnterRepository': 'Please enter the name of the repository',
    'RepositoryLengthLimit': 'Repository names are between 1 and 30 characters long',
    'RepositoryLimit': 'Repository names should be lowercase letters, numbers, special symbols (-_.) , cannot begin with _.',
    'WarehouseModified': 'The warehouse has been modified',
    'WarehouseAdded': 'The warehouse has been added',
    'RepositoryNameExists': 'Repository name already exists',
    'Deleted': 'Deleted',
    'ContentError': 'The content to be deleted is filled in incorrectly',
    'ConnectedFine': 'The remote repository is connected fine.',
    'ConnectedFailed': 'Remote repository connection failed',
    'ResponseStatusCode': 'Response status code',
    'warehouseTips': '(tips: Some repositories do not support browsing will also return 404, but it does not affect the build use, please check whether the remote repository is such a repository)',
    'WarehouseSetup': 'Warehouse setup',
    'PermissionSetting': 'Permission setting',
    'TimingPolicy': 'Timing policy',
    'FederatedRepository': 'Federated repository',
    'Scan': 'Scan',
    // LibView.vue
    'Details': 'Details',
    'Statistics': 'Statistics',
    'MakingHistory': 'Making history',
    'ProductPath': 'Product path',
    'CreationTime': 'Creation time',
    'LastTimeOfUse': 'Last time of use',
    'NumberOfDownloads': 'Number of downloads',
    'ProductSize': 'Product size',
    'CVENumber': 'CVE number',
    'VulnerabilityLevel': 'Vulnerability level',
    'CvssV2VulnerabilityLevel': 'CvssV2 vulnerability level',
    'CvssV3VulnerabilityLevel': 'CvssV3 vulnerability level',
    'CvssV2Score': 'CvssV2 score',
    'CvssV3Score': 'CvssV3 score',
    'ImportVersion': 'Import version',
    'VulnerabilityNumber': 'Vulnerability number',
    'IntroducingTime': 'Introducing time',
    'HighestVulnerabilityLevel': 'The highest vulnerability level',
    'RecommendedFixVersion': 'Recommended fix version',
    'Operation': 'Operation',
    'TheNumbers': 'Number',
    'String': 'String',
    'Text': 'Text',
    'OperationSuccessful': 'Operation successful',
    // Scanner.vue
    'ScannedPacketsNum': 'Total number of scanned packets',
    'InsteadOfScanningPacketsNum': 'Total number of packages skipped from scanning',
    'SuccessScannedPacketsNum': 'Number of packages successfully scanned',
    'FailedScansNUM': 'Number of packages failed to scan',
    'ScanDependenciesNum': 'Scan the number of dependencies',
    'VulnerablePackagesNum': 'Number of vulnerable packages',
    'VulnerabilitiesTotalNum': 'Total number of vulnerabilities',
    'sealedVulnerabilitiesNum': 'Number of sealed vulnerabilities',
    'Compare': 'Vulnerability distribution view for the past 7 days',
    'packagesNum': 'The number of packages is ',
    'unknown': ' unknown compared with last week',
    'unchanged': ' unchanged from last week',
    'dataCompared': "Here are the results of this week's data compared to last week's data (14 days).",
    'NewVulnerabilityPackage': 'New vulnerability package',
    'ScanForNewDependencies': 'Scan for new dependencies',
    'NewVulnerabilityDependency': 'New vulnerability dependency',
    'NewSealedVulnerabilitiesNum': 'Number of new sealed vulnerabilities',
    '30daysData': 'Data in the last 30 days',
    '30daysDataChat': 'This figure shows the data of the last 30 days, and the dates without new data are not shown',
    'VulnerabilitiesNum': 'Number of vulnerabilities',
    'WarehouseScanningSituation': 'Warehouse scanning situation',
    'PlatformVulnerability': 'Platform vulnerability',
    'DependingOnQuantity': 'Depending on quantity',
    'Warehouse': 'Warehouse',
    'TotalNumberOfPackets': 'Total number of packets',
    'NumberOfQuestions': 'Number of questions',
    'SecurityScore': 'Security score',
    'EventRecord':'Event record',
    'PromotionRecord':'Promotion record',
    'GitRepositoryAddr': 'Git repository Addr',
    'GitProviderCredential': 'Git private repository credentials',
    'GitUsername': 'Git repository username',
    'GitPassword': 'Git repository password/token',
    'GitProvider': 'Git Provider',
    'AddGitCredential': 'add Git repository Credential',
    'StorageOverview': 'Storage overview',
    'FilesSize':'Files size',
    'FilesCount':'Files count',
    'RepositoryCount': 'Repository count',
    'ArtifactsSize':'Artifacts size',
    'ArtifactsCount':'Artifacts count',
    'FoldersCount':'Folders count',
    'ItemsCount':'Items count',
    'StorageDeviceType': 'Storage device type',
    'StorageQuotaSize':'Storage quota size',
    'DataUpdateTime': 'Latest update time of data',
    'LastTenUpdates': 'Last 10 updates to the storage data view',
    'RepositoryList': 'Repository list',
    'ScanTime': 'Scan time',
    'RepositoryQuery': 'Please select repository query',
    'RepositoryInfo':'Repository info',
    'packageTypeQuery':'Select package type search',
    'StrategyTypeQuery':'Select strategy type search',
    'RepositoryNameQuery':'Enter the Repository Name search',
    'Loading':'Loading more',
    'Save':'save',
    'raw':'Raw (also called Generic) is a generic type that can be used to store various artifacts such as documents, SQL scripts, and environment configuration information. The Generic warehouse can also be used to manage product packages, publish product packages to the warehouse, and create hierarchical directories based on enterprise business rules (such as system-sub-system-version-release file, release configuration, and release description), which facilitates service management of product packages.',
    'generic': 'Generic is a generic type that can be used to store various products, such as documents, SQL scripts, and environment configuration information. The Generic warehouse can also be used to manage product packages, publish product packages to the warehouse, and create hierarchical directories based on enterprise business rules (such as system-sub-system-version-release file, release configuration, and release description), which facilitates service management of product packages.',
    'gitlfs':'Git LFS (Large File Storage) is an extension of Git. Git LFS replaces large files in the code repository with pointer files, which declare the real storage information of the large file. This repository supports local, proxy, and combination for storing large files in Git repositories, declaring warehouse information through.lfsconfig file, and tracking files to publish large files to the repository when submitted. Please refer to the warehouse guide for operation during use.',
    'cocoapods':'In the development of iOS projects need to use third-party open source libraries, CocoaPods is a tool to help us manage third-party dependent libraries, it can solve the dependency relationship between libraries. The repository supports open source cocoapods-art plug-in client, which can centrally manage dependencies through Folib repository and improve the dependency pulling speed. For details about how to use the warehouse, see the help for creating the warehouse.',
    'php':'Composer is a tool that PHP uses to manage dependency relationships. You can declare external libraries that you depend on in your project and Composer will install them for you. The Composer repository is used to store PHP dependencies.',
    'debian':'Debian GNU/Linux is an operating system and free software distribution, and apt is a CLI tool for managing software packages on Debian-based Linux systems. Debian warehouse can be used as the package center of Debian operating system, storing packages according to the official rules of Debian, and pulling packages through APT in use.',
    'docker':'Docker is an open source containerization platform that allows developers to package applications and their dependencies into a lightweight, portable container. The Docker warehouse can be used as a proxy for image centers such as Docker Hub. The local warehouse is used to store the container images built by Docker clients, and can also be used in virtual combination. Support buildx multi-architecture, online upload tar package automatic decompression identification and other functions.',
    'conan':'Conan is a package manager for C and C++ developers. Through the Conan repository, you can store the dependency packages and binary packages required for C and C++ projects, and you can use it as a proxy for Conan external sources, local uploads, and virtual combinations.',
    'go':'The Go warehouse can be used as Golang dependent warehouse. By configuring the image source, it supports the combination of Go-dependent pull proxy, local upload, and virtual.',
    'gradle':'Gradle is an advanced build automation tool designed to efficiently build and manage projects of all sizes, especially Java and Android projects. Gradle repositories can be used as proxies for Java, Android, Kotlin, Groovy, Scala dependency repositories, and local repositories are used to store project packages generated after Gradle builds, and can also be used in virtual combinations.',
    'helm':'Helm defines Kubernetes applications using the template file of Charts, which contains all the necessary information for creating Kubernetes resources such as Deployment, Service, ConfigMap, and so on. The Helm warehouse acts as a proxy for Charts warehouses such as the Helm Official Warehouse, while the local warehouse is used to store Helm Charts packages.',
    'maven':'Apache Maven is a Java project management and understanding tool that includes a project object model (POM), a set of rules and management of project dependencies, and a build process that will define a set of binding goals for building projects. Maven warehouse can be proxy Maven central warehouse and other dependent warehouses, the local warehouse is used to store the project Jar package generated after Maven construction, support online batch upload automatic identification coordinates.',
    'npm':'Npm is the official Node.js package management tool for publishing, propagating, and controlling dependencies on Node.js packages. The Npm warehouse can act as a proxy for the Npm official warehouse and other dependent warehouses, while the local warehouse is used to store the project packages generated after Npm is built.',
    'nuget':'NuGet is an open source package management system on the.NET Framework and.NET Core/Standard platforms designed to simplify the process of using, creating, and managing libraries in.NET applications. NuGet warehouse is mainly used to manage the project dependencies of C#, VB.NET and other.NET related languages, and can act as a proxy for NuGet official warehouse and other dependent warehouses, supporting local storage of the generated project packages.',
    'ohpm':'OHPM is a package management tool for the OpenHarmony tripartite library, supporting the release, installation, and dependency management of OpenHarmony shared packages. The OHPM repository is compatible with the OHPM package manager, the remote repository can broker the OpenHarmony tripartite library center and cache all dependencies on demand, and the local repository can be used as a private dependency repository to store private dependencies in Hongmon development.',
    'pub':'Pub is the official tool for managing and publishing code packages in the Dart language, and the Flutter framework is a popular multi-platform UI toolkit powered by the Dart language. The Pub repository can be used to manage Dart and Flutter language dependencies.',
    'pypi':'PyPI (Python Package Index) is a core component of the Python community and provides Python developers with the primary source of third-party libraries. The PyPI warehouse can act as a dependent warehouse such as the PyPI official warehouse, and the local warehouse is used to store project packages generated by PyPI publishing.',
    'rpm':'The Red Hat Package Manager (RPM) is a package management tool for Red Hat-based operating systems (such as Fedora, CentOS, RHEL, etc.), providing the ability to install, uninstall, upgrade, query, and verify software packages. The RPM repository provides RPM-oriented package publishing and pulling capabilities.',
    'rust':'In the Rust ecosystem, Cargo is the official build system and package manager that supports dependency management and build automation. Cargo repositories can broker and cache Rust official dependencies for faster pull times, and can create local repositories to store private Rust dependencies or built artifact packages.',
    'ivy':'Apache Ivy is a flexible dependency management tool commonly used in Java projects. Ivy repositories can proxy external Ivy dependency sources, and support local upload and virtual combination use, suitable for storing and managing complex dependency project libraries.',
    'sbt':'SBT (Simple Build Tool) is the most popular build tool for Scala projects, managing project dependencies and automating builds. SBT warehouse can proxy external SBT warehouse sources, support local upload of generated project packages, and virtual combination use.',
    'yarn':'Yarn is a fast, secure, and reliable tool for managing JavaScript packages. The Yarn warehouse can act as an agent for npm or other Yarn official warehouses, and supports local upload and virtual combination to provide an efficient dependency management solution for team collaboration.',
    'huggingface':'Hugging Face is a widely used library of natural language processing and machine learning tools. Its warehouse supports the storage and proxy Hugging Face official models and datasets, provides the function of locally uploading customized models, and supports virtual combination operations, satisfying the requirements of releasing and distributing models and datasets in AI projects.',
    'gems':'RubyGems is a package management system for the Ruby programming language that manages the installation, distribution, and dependency resolution of gems (software packages in Ruby). Gems warehouse can broker external Gem sources (such as RubyGems.org), support local upload and storage of private Gem packages, while providing virtual composition function, easy team collaboration and unified management of Gem packages.',
    'conda':'Conda is a fast, cross-platform, open source package management and environment management system commonly used in data science, machine learning and AI development. Conda repositories can be used to manage dependency packages for Python and other languages, support proxy Anaconda and Miniconda sources, and store and distribute custom environments natively.',
    'opkg':'Opkg (Open PacKaGe Management) is a lightweight package management tool designed specifically for embedded Linux systems, such as the OpenWrt and Yocto projects. The Opkg repository can be used to manage software packages and dependencies required by embedded devices, supporting local upload and proxy external sources.',
    'swift':'Swift is a modern programming language introduced by Apple for the development of iOS and macOS apps. Swift Package Manager (SPM) is Swift‘s official package management tool. The Swift repository can store and distribute SPM packages, proxy external Swift package sources, cache them on demand, and support local upload and virtual combination to meet development teams requirements for dependency management and distribution.',
    'cargo':'In the Rust ecosystem, Cargo is the official build system and package manager for dependency management and build automation, and the Cargo repository proxies and caches official Rust dependencies for faster pulling and creates local repositories for storing private Rust dependencies or built artifact packages.',
    // 'rust':'rust',
    // 'php':'php',
    'StorageThreshold': 'Storage Threshold',
}
