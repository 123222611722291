import en from './lang/en';
import zh from './lang/zh-CN';
import FederalPromotionPolicy from "../index.vue";

export default {
    computed: {
        FederalPromotionPolicy() {
            return FederalPromotionPolicy
        }
    },
    en, zh,
};
