export default {
    'CustomLayoutList': '自定义布局列表',
    'YES': '是',
    'NO': '否',
    'Total': '个',
    'LayoutName': '布局名称',
    'ArtifactPathPattern':'制品路径正则表达式',
    'CustomLayoutSetting':'自定义布局设置',
    'EnterLayoutName':'请输入布局名称',
    'EnterArtifactPathPattern':'请输入制品路径正则表达式',
    'EnterCustomLayoutNameQuery': '输入布局名称查询',
    'Operate': '操作',
    'OperationSuccessful': '操作成功',
}