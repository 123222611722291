export default {
    'StrategyList': '策略列表',
    'YES': '是',
    'NO': '否',
    'Total': '个',
    'StorageId': '存储空间',
    'RepositoryId': '仓库名称',
    'StrategyName': '备份策略名称',
    'Repositories': '仓库信息',
    'CronExpression':'Cron设置',
    'Enabled':'启用',
    'BackupStrategySetting':'备份策略设置',
    'EnterStrategyName':'请输入备份策略名称',
    'ChooseBackupRepository':'选择备份仓库',
    'SelectableRepositories':'可选择的仓库',
    'SelectedRepositories':'已选择的仓库',
    'EnterCronExpression':'请输入Cron表达式',
    'BackupPath':'备份目录',
    'Incremental':'增量备份',
    'IncrementalTip':'若开启此设置，会在备份目录下创建incremental目录，首次执行会备份所有制品至该目录。后续执行是增量的，新增和修改的制品会被写入到该目录下。若关闭此设置，则是全量备份模式，每执行一次备份会在备份目录中按照备份时间创建全量备份目录。',
    'RetentionPeriod':'全量备份保留期限（天）',
    'RetentionPeriodTip':'备份模式若为全量备份，每执行一次备份，会在备份目录中按照备份时间创建全量备份目录。该设置是指历史全量备份目录数据的保留期限，默认值为7天',
    'ChooseBackupPath':'选择备份目录',
    'EnterBackupStrategyNameQuery': '输入备份策略名查询',
    'EnterStorageIdQuery': '输入存储空间查询',
    'EnterRepositoryIdQuery': '输入仓库查询',
    'Operate': '操作',
    'OperationSuccessful': '操作成功',
}