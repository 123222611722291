export default {
    // index.vue
    'GotoBrowsePage': '点击可进入浏览页面',
    'BrowseAddress': '浏览地址',
    'WarehouseBrowseAddress': '仓库浏览地址，点击可进入浏览页面',
    'UseAddress': '使用地址',
    'WarehouseUsageAddress': '仓库使用地址，具体使用方法，请看页面右侧使用帮助',
    'BatchUpload': '批量上传',
    'UseHelp': '使用帮助',
    'ScanOn': '扫描开始',
    'ScanOff': '扫描关闭',
    'PacketList': '包列表',
    'TrashCan':'回收站',
    'Seriousness': '严重',
    'HighRisk': '高危',
    'MediumRisk': '中危',
    'LowRisk': '低危',
    'Health': '健康',
    'ScanFailure': '扫描失败',
    'More': '更多',
    'Package': '包',
    'Document': '文件',
    'Details': '详情',
    'Preview': '预览',
    'Copy': '复制',
    'Move': '移动',
    'SuerDelete': '确定要删除吗？',
    'Delete': '删除',
    'Distribute': '分发',
    'DownLoad': '下载',
    'Confirm': '确定',
    'Cancel': '取消',
    'ProductionHistory': '制作历史',
    'TargetWarehouse': '目标仓库',
    'InputTargetWarehouse': '请输入目标仓库',
    'SelectTargetWarehouse': '请选择目标仓库',
    'TargetDirectory': '目标目录',
    'InputTargetDirectory': '请输入目标目录',
    'Submit': '提交',
    'SelectFile': '选择文件',
    'PleaseSelectFile': '选择文件',
    'UploadMode': '上传方式',
    'SelectUploadMode': '请选择上传方式',
    'Product': '制品',
    'ZipUpload': '压缩包',
    'ArtifactSingleUploadLimit': '一次只能上传一个制品',
    'NonStandardArtifact': '非标准制品，请手动填写制品信息',
    'ProductUpload': '此方式支持多个制品包批量上传，一次不能超过10个文件',
    'ZipFileUpload': '此方式支持上传一个ZIP文件格式的压缩包，大小不能超过',
    'unzippedDirectory': '压缩包将会解压到仓库内的此目录下',
    'NodeType': '节点类型',
    'NodeTypeSelect': '请选择节点类型',
    'InternalNode': '内部节点',
    'ProductWarehouseNode': '制品库节点',
    'ExternalNode': '外部节点',
    'OtherTypeNode': '其他类型制品库节点',
    'selectAll': '全选',
    'NoData': '暂无数据',
    'OwnedStorage': '存储空间',
    'OwnedWarehouse': '所属仓库',
    'ProductPath': '制品路径',
    'CreationTime': '创建时间',
    'LastUsedTime': '最近使用时间',
    'DownloadTimes': '下载次数',
    'ProductSize': '制品大小',
    'ServiceShutdown': '该仓库已关闭服务',
    'BrowseNotEnabled': '该仓库目录浏览未开启',
    'CopySuccess': '复制成功',
    'OperationSuccess': '操作成功',
    'CheckProgress': '请至页面右上角上传进度中查看',
    'EncodingError': '错误编码: ',
    'UploadZipPackage': '只能上传一个压缩包',
    'fileSize': '文件大小不能超过',
    'ZIPFormat': '必须上传zip格式的文件!',
    'UploadCount': '一次上传不能超过10个文件!',
    'DirectoryFormat': '目标目录不能以/开头',
    'CopyCustomDirectory': '复制到自定义目录',
    'MoveCustomDirectory': '移动到自定义目录',
    'DistributeCustomDirectory': '分发到指定目录',
    'Copying': '复制中，请稍候查看',
    'Moving': '移动中，请稍候查看',
    'Distributing': '分发中，请稍候查看',
    'DeletionSuccessful': '删除成功',
    'DeletionFailed': '删除失败',
    'RestoreSuccessful':'还原成功',
    'RestoreFailed':'还原失败',
    'CannotPreview': '该制品无法预览',
    'PacketListView':'包列表视图',
    'TrashCanView':'回收站视图',
    // Data.vue
    'BasicInformation': '基本信息',
    'OwningSpace': '所属空间',
    'Name': '名称',
    'ThePath': '路径',
    'FileSize': '文件大小',
    'ArtifactMaxSize': '制品大小限制',
    'ModifyTheTime': '修改时间',
    'ImageName': '镜像名称',
    'VersionNumber': '版本号',
    'NumberOfFloors': '层数',
    'MakeADockerVersion': '制作Docker版本',
    'MirrorOS': '镜像OS',
    'TheInfrastructure': '基础架构',
    'Metadata': '元数据',
    'MetadataKey': '元数据Key',
    'Create': '新增',
    'View': '查看',
    'ConanInformation': 'Conan信息',
    'ConanBagInfo': 'Conan包信息',
    'Configure': '配置',
    'PackageName': '包名',
    'User': '用户',
    'Channel': '频道',
    'Quote': '引用',
    'TheAuthor': '作者',
    'Permission': '许可',
    'PackageInformation': '包信息',
    'NumberOfPackets': '包个数',
    'UseExamples': '使用示例',
    'Number': '数字',
    'String': '字符串',
    'Text': '文本',
    'MetadataType': '元数据类型',
    'MetadataValues': '元数据值',
    'Operations': '操作',
    'DeletedProductSuccess': '删除制品元数据成功',
    'BomInformation': 'Bom信息',
    'Restore':'还原',
    'RestoreConfirm':'您确定要将其恢复到仓库{targetRepositories}的{path}位置吗？',
    // UseDoc
    'Instructions': '使用说明',
    'GlobalConfiguration': '全局配置',
    'Configuration': '配置',
    'SourceConfiguration': '源配置',
    'copyConfiguration': '你需要复制以下配置到你的maven的/conf/settings.xml中',
    'POMConfiguration': '通常需要在pom.xml中进行指定上传的配置，和常用的maven仓库一样使用,具体pom配置可参阅：',
    'warehouseType': '注意：本仓库类型为: ',
    'ProxyLibrary': '代理库',
    'CombinatorialLibrary': '组合库',
    'LocalLibrary': '本地库',
    'NotUpload': '不支持上传',
    'SupportUpload': '可以上传',
    'CommandOperation': '命令操作',
    'UsuallyCommand': '通常使用命令',
    'UsuallyUse': '和通常',
    'specificRefer': '一样使用，具体参阅：',
    'ConfigWarehouse': '配置仓库',
    'gradleCode': '你需要在 build.gradle 文件中加入以下代码:',
    'SBTCode': '你需要编辑或新建 ${HOME}/.sbt/repositories，文件中加入以下代码:',
    'SBTGlobalConfig': '编辑 ${sbt_安装目录}/conf/sbtconfig.txt，如果你使用的 idea，在settings->SBT-> jvm parameters 添加',
    'UseGlobalConfig': '忽略工程自定义的 resolvers，采用全局配置',
    'ivyCode': '你需要修改 ${USER_HOME}/.ivy2/ivysettings.xml，文件中加入以下代码:',
    'ConfiguringGlobalConfig': '配置全局配置',
    'ConfigMirror': '你可以全局配置npm的mirror,操作如下:',
    'OhpmConfigMirror': '你可以全局配置ohpm的mirror,操作如下:',
    'ViewNpm': '查看npm当前配置',
    'EngineerAllocation': '工程配置',
    'validCurrent': '该方式只对当前工程生效',
    'npmrcCode': '需要在仓库下创建.npmrc文件并填入如下：',
    'RPMOperation': '在/etc/yum.repos.d/中添加一个local_test.repo文件,镜像服务器为阿里云,操作如下:',
    'folibOperation': '将folib helm仓添加到本地操作步骤',
    'ChartPackage': '上传Chart包到Helm仓库',
    'HelmPlugin': '安装 helm-cm-push插件',
    'HelmCommand': '详细使用参考官网',
    'ForReference': '仅供参考，详情请查相关文档',
    'conanConfig': '将folib conan仓添加到本地操作步骤',
    'ConanOperation': '相关操作',
    'YarnConfig': '你可以全局配置Yarn的mirror,操作如下:',
    'PypiConfig': '编写.pypirc配置文件如下:',
    'PackageUpload': '打包上传',
    'DesignatedRepository': '该方式打包时指定仓库',
    'followingCommand': '命令如下：',
    'DockerVersions': '针对Docker客户端版本大于 1.10.0 的用户：',
    'modifyDaemon': '您可以通过修改daemon配置文件/etc/docker/daemon.json来使用:',
    'MacDocker': '针对安装了Docker for Mac的用户，您可以参考以下配置步骤：',
    'clickIcon': '在任务栏点击 Docker Desktop 应用图标 ->',
    'selectLeft': '在左侧导航菜单选择 Docker Engine，',
    'EditJson': '在右侧输入栏编辑 json',
    'will': '文件。将:',
    'clickButton': '加到"insecure-registries"的数组里，点击 Apply &',
    'waitDocker': 'Restart按钮，等待Docker重启',
    'WindowsDocker': '针对安装了Docker for Windows的用户，您可以参考以下配置步骤：',
    'RightClickMenu': '在系统右下角托盘图标内右键菜单选择',
    'LeftClickMenu': 'Settings，打开配置窗口后左侧导航菜单选择 Docker',
    'EditJSON': 'Daemon。编辑窗口内的JSON串，填写下方地址：',
    'ImagePackage': '镜像打包命名说明',
    'ImageBuildx':'buildx的使用方式',
    'ImageBuildxTips':'采用buildx可以支持同一个tag展示多种架构的制品',
    'ImageBuildxRules':'buildx的使用方式和常规的一样使用，如下:',
    'ImagePackageTips': '请一定要看，这决定了镜像包能否上传:',
    'ImageNamingRules': '镜像命名规则如下：仓库访问url/存储空间/仓库名称/镜像名称:版本号，具体如下：',
    'URLRepository': '添加默认推送存储库',
    'SeeDocumentation': '示例如下，详细请看文档',
    'example': '以下为示例',
    'accessFolib': '为了方便访问folib可将 -Source 选项附加到 NuGet.exe：',
    'VSConfig': 'Visual Studio中的详细配置请看平台帮助文档',
    'Authentication': '认证',
    'ExecuteCommand': '打开命令行窗口（windows用户）或控制台（Linux、Mac 用户）并执行如下命令：',
    'Method1': '方法一： 修改 composer 的全局配置文件（推荐方式）：',
    'Method2': '方法二： 修改当前项目的 composer.json 配置文件',
    'rootProject': '打开命令行窗口（windows用户）或控制台（Linux、Mac 用户），进入你的项目的根目录（也就是 composer.json 文件所在目录），执行如下命令：',
    'Unconfigure': '取消配置',
    'GlobalCancel': '全局取消',
    'ProjectCancel': '项目取消',
    'useAPI': '使用API或页面上传按钮进行上传',
    'GeneralPurpose': '通用',
    'useCocoapods': '为了使用CocoaPods，你需要安装"cocoapods-art"插件。安装cocoapods-art命令如下:',
    'UseStandards': 'repo-art使用标准',
    'SpecifyAuthentication': '中指定的身份验证',
    'addLibrary': '要添加一个Cocoapods库:',
    'Deployment': '部署:',
    'podDeployment': '要将pod部署到存储库中，您需要使用REST API或Web UI。',
    'podDeployExample': '例如，要使用REST API将pod部署到此存储库，请使用以下命令:',
    'PlugIntegration': '插件集成',
    'parsePod': '要从您添加的规范存储库中解析 Pod，您必须将以下内容添加到 Podfile 中：',
    'use': '然后，您可以像往常一样使用安装：',
    'WarehouseAddress': '仓库地址',
    'WarehouseUseAddress': '仓库使用地址',
    'Client': '客户端',
    'ConanRepositoryAdd': '将conan仓添加到本地',
    'ConanLogin': '添加访问用户名密码',
    'ConanSearch': '搜索本地已有的包',
    'ConanCreate': '打包到本地并下载依赖',
    'ConanUpload': '上传本地包',
    'ConanRemoteList': '查询已添加的远程仓库',
    'NpmConfig': '查看npm当前配置',
    'IS': '是',
    'NpmAuth': '你也可以采用用户名密码模式',
    'NpmInstall': '安装依赖',
    'NpmPublish': '上传制品',
    'SBTIgnore': '忽略工程自定义的 resolvers，采用全局配置',
    'RpmClean': '清除YUM缓存',
    'RpmRepoList': '显示所有仓库',
    'HelmCMPush': '下载各个系统下的 helm-cm-push 安装包',
    'HelmDecompression': '把安装包复制到 helm 的plugins目录下解压',
    'HelmUploadCmd': '命令上传',
    'HelmUploadCmdBin': '进入 helm-cm-push plugins 插件bin目录       #helm env 查看plugins目录位置',
    'HelmUploadExecute': '执行上传',
    'HelmUploadExample': '例如 ：上传/app/fluentd-4.5.2.tgz 的chart包 到',
    'HelmUploadParamsExplain': '参数说明：第一个参数是cahrt 包全路径   第二个参数是加入到本地的helm 仓库名. --username  --password 可选鉴权使用',
    'HelmRepoUpdate': '更新本地仓库',
    'HelmRepoSearch': '搜索本地的mysql charts',
    'HelmPull': '将最新的mysql 下载到本地  --version 可指定版本',
    // addMetadata
    'Modify': '修改',
    'Custom': '自定义',
    'PleaseSelect': '请选择',
    'PleaseEnter': '请输入',
    'Type': '类型',
    'value': '值',
    'EnterSomethingHere': '在此处输入内容',
    'KEYLength': '长度在 1 到 30 个字符',
    'ItExists': '已存在',
    'MetadataSuccess': '制品元数据成功',
    //go
    'GoProxyConfig':'配置go全局代理',
    'useGo':'为了使用此代理，你需要通过以下方式修改go的全局代理',
    'CacheStatus': '缓存状态',
    'Uncached': '未缓存',
    'ProductDetails': '制品详情',
    'SearchList': '搜索列表',
    'StartDate': '开始日期',
    'EndDate': '结束日期',
    'SelectDate': '选择日期',
    'DisplayQuantity': '显示数量',
    'Unassigned': '未分配',
    'RiskScore': '风险评分',
    'NumberOfComponents': '组件数量',
    'VulnerabilitiesNum': '漏洞数量',
    'ViewDetail': '查看详情',
    "SshKeygen":"利用工具ssh-keygen生成公、私钥",
    "PrivateKeyPath":"设置私钥路径",
    "SetPublishingId":"设置发布用户 ohpm客户端低于1.6.0版本",
    "SetPublishingIdPlus":"设置发布用户 ohpm客户端1.6.0及以上版本",
    "SetRepository":"设置仓库",
    "SetPublishingRepository":"设置发布库",
    "SslCheck":"ssl校验",
    "OhpmConfig":"查看当前ohpm配置",
    "PublishIdTip": "your_publish_id 为 Base64(用户名:密码) 编码后的结果，例如用户名为example，密码为password，则 your_publish_id 为ZXhhbXBsZTpwYXNzd29yZA==",
    "GoGet": "添加依赖",
    "GoClean": "清除依赖缓存",
    "GoDownload":"下载依赖项",
    "GoUploadPreparation": "上传go制品准备",
    "GoUploadCreateFile": "上传go制品前需要准备以下三个文件",
    "GoFilenameFormat":"文件名称格式",
    "Version":"版本号",
    "Filename":"文件名称",
    "FileContent":"文件内容",
    "GoInfoFormat":"内容为制品信息，格式为以下内容",
    "GoInfoTime":"当前UTC时间",
    "Example":"示例",
    "ExampleVersion":"版本号",
    "GoModFormat":"*.mod内容与工程的go.mod的内容保持一致",
    "GoZipFormat":"*.zip为工程源码的压缩包，其压缩前目录结构为go.mod中的module值+@版本号",
    "GoZipConentTip1":"以上述module值和版本号为例，其打包目录应为example.com/example/hello-world@v1.1",
    "GoZipConentTip2":"将源码放入example.com/example/hello-world@v1.1目录中后压缩为v1.1.zip",
    "GoUploadCommand":"执行上传命令",
    "GoUploadUseFolib":"使用folib命令行工具上传",
    "Login":"登录",
    "Upload":"上传",
    "ParameterExplanation":"参数解释",
    "StorageName":"存储空间名称",
    "RepositoryName":"仓库名称",
    "TargetPath":"制品在仓库中的存放路径，格式为go.mod中的module值+@v+文件名称",
    "ArtifactSelf":"制品文件",
    "ArtifactThree":"三个文件依次上传至制品库",
    'LfsInitialization':'初始化',
    'LfsAddFile':'添加文件到 lfs',
    'SetLfs':'设置GitLfs仓库',
    'ShowLfs':'查看配置',
    "LfsClone":"克隆",
    "LfsPull":"拉取",
    "LfsPush":"推送",
    "LfsAddLock":"锁定文件",
    "LfsGetLocks":"查看锁定状态",
    "LfsUnlock":"解锁文件",
    "LfsFilename":"文件名",
    "HuggingFaceMLConfigure":"配置",
    "HuggingFaceMLConfigureInfo":"要配置 Hugging Face 客户端与 FoLib 协同工作，请使用以下命令添加您的版本库",
    "HuggingFaceMLConfigureInfo2":"对于 0.19.0 及以上版本的 Hugging Face 客户端，HF_HUB_ETAG_TIMEOUT 参数允许您使用管道和标记符解析模型。",
    "HuggingFaceMLConfigureToken":"然后，要通过 FoLib 验证 Hugging Face 客户端，请运行以下命令。",
    "HuggingFaceMLUpload":"上传模型",
    "HuggingFaceMLUploadInfo":"要将模型上传到 FoLib 资源库中，需要使用 huggingface_hub 库:",
    "HuggingFaceMLDownload":"下载模型",
    "HuggingFaceMLDownloadInfo":"要从 FoLib 资源库解析一个模型，需要使用 huggingface 客户端命令:",
    "HuggingFaceMLDownloadInfo2":"如果您使用的是 0.19.0 及以上版本的 Hugging Face 客户端并启用了 HF_HUB_ETAG_TIMEOUT 参数，现在可以使用变换器和扩散器等库解析模型。",
    "HuggingFaceMLDownloadInfo3":"了解更多信息",
    //pub
    'PubConfigMirror': '你可以全局配置pub的mirror,操作如下:',
    "PubAdd": "添加依赖",
    "PubCacheClean": "清除依赖缓存",
    "PubGet":"下载依赖项",
    "PubPublish":"上传制品",
    "PubPublishConfig":"上传制品配置",
    "PubPublishConfigMirror":"在pubspec.yaml中配置publish_to为当前仓库",
    "PubPublishTokenConfig":"添加授权信息",
    "GetToken": "获取token",
    "AddToken": "向Pub客户端添加令牌，如果仓库允许匿名访问，则无需该操作",
    "ExportPub": "为了让Pub客户端使用该仓库，您需要运行以下命令设置环境变量",
    'ImageTag': '镜像标签',
    'InputImageTag': '请输入镜像标签',
    "ImageTagSpecification": "镜像标签规范: [PATH]/[NAME]:[TAG]",
    'Image': '镜像文件',
    'SubsidiaryFiles': '附属文件',
    "Prompts":"提示",
    "DirSize": "目录下文件大小是 ",
    "ConfirmDownload": "确定下载吗？",
    'ScanTime': '扫描时间',
    "UploadFailed": "文件上传失败",
    'RepositoryType':'仓库类型',
    'StrategyType': '策略类型',
    'ProxyAddress':'代理地址',
    'GroupRepositories':'组合仓库',
    // Debian
    'DebianGlobalConfiguration': '首先需要将仓库相关信息添加至"/etc/apt/sources.list文件， 执行如下命令：',
    'DebianPermissionConfiguration': '如需要将凭证信息添加至"/etc/apt/sources.list"文件，执行如下命令：',
    'DebianCommandOperation':'配置完毕后使用如下命令安装包：',
    'CargoGlobalConfiguration': '要将Cargo客户端指向FoLib仓库，请编辑Cargo主目录下的配置文件（例如~/.cargo/config.toml）：',
    'CargoGlobalConfigurationToken': '为API调用（如publish和yank）设置凭据，请将以下部分添加到Cargo主目录下的凭据文件（例如~/.cargo/credentials.toml）：',
    'CargoDeploy':'发布',
    'CargoDeployConfig':'要发布一个crate并将其上传到FoLib，请使用cargo publish命令。',
    'CargoInstall': '安装',
    'CargoInstallConfig': '要安装一个crate，请使用cargo install命令。',
    'Recursive': '递归',
    'RecursiveTip': '元数据将添加到所选文件夹以及此文件夹下的所有制品、文件夹和子文件夹中。',
    'RecursiveDelete': '递归删除',
    'Edit': '编辑',
}
