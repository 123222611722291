export default {
    "PolicyId": "policy id",
    "PolicyName": "policy name",
    "IsEnabled": "Whether or not to enable the federal promotion strategy",
    "CreatedTime": "created Time",
    "UpdateTime": "update Time",
    "CreatedBy": "created By",
    "UpdateBy": "update By",
    "SourceRepositories": "Source Repositories",
    "TargetRepositories": "Target Repositories",
    "computing": "computing",
    "disable": "disable",
    "CardTitle":"list Of Federal Promotion Strategies",
    "AddTitle":"Added Federal Promotion Strategy",
    "DrawerTitle":"Federal Promotion Strategy Settings",
    "PathRule":"Packet Path Rules",
    "MetaDataRule":"Metadata Rules",
    "SelectSourceRepositories":"Select The Federated Promotion Source Depot",
    "SelectNodeType":"Select the target node type",
    "InternalNode":"Internal Node",
    "ExternalNode":"External Node",
    "SelectingTargetNode":"Selecting the target node",
    "SelectionTargetRepositories":"Selection of Federal Advancement Target Repositories",
    "PleaseInput":"Please Input",
    "PleaseSelect":"Please Select",
    'SelectableRepositories':'Selectable repositories',
    'SelectedRepositories':'Selected repositories',
    'OperationSuccessful': 'Operation successful',
    'OperationFailure':'Operation Failure',
    'IsDeleteSync':'Whether to enable delete synchronization',
    "StorageId": "Storage",
    "RepositoryId": "Repository name",
    "Total": "Total",
    
}