export default {
    // Artifacts.vue
    'ArtifactPathQuery': 'Enter artifact path query',
    'StorageSpace': 'storage space',
    'OwnedWarehouse': 'Owned warehouse',
    'ProductPath': 'Product path',
    'LastUsedTime': 'Last used time',
    'DownloadTimes': 'Download times',
    'ProductSize': 'Product size',
    'Vulnerability': 'Vulnerability',
    'RepositorySelect': 'Please select a repository for query',
    // index.vue
    'GeneralView': 'General view',
    'Component': 'Component',
    // ArtifactDashboard.vue
    'OwningSpace': 'Owning space',
    'FileSize': 'File size',
    'ModifyTheTime': 'Modify the time',
    'ServiceTime': 'Service time',
    'ImageName': 'Image name',
    'VersionNumber': 'Version number',
    'NumberOfFloors': 'Number of floors',
    'MakeADockerVersion': 'Make a Docker version',
    'MirrorOS': 'Mirror OS',
    'TheInfrastructure': 'The infrastructure',
    'Total': 'Total',
    'Seriously': 'Seriously',
    'MediumRisk': 'Medium risk',
    'HighRisk': 'High risk',
    'LowRisk': 'Low risk',
    // ArtifactComponents.vue
    'componentNameQuery': 'Enter a component name to query',
    'ComponentName': 'Component name',
    'Group': 'Group',
    'Licence': 'Licence',
    // ArtifactVulnerability.vue
    'EnterVulnerabilityNumber': 'Enter the vulnerability number query',
    'VulnerabilityDescription': 'Vulnerability description',
    'VulnerabilityLevel': 'Vulnerability level',
    'CvssV2VulnerabilityLevel': 'CvssV2 vulnerability level',
    'CvssV3VulnerabilityLevel': 'CvssV3 vulnerability level',
    'CvssV2Score': 'CvssV2 score',
    'CvssV3Score': 'CvssV3 score',
    'ImportVersion': 'Import version',
    'VulnerabilityNumber': 'Vulnerability number',
    'IntroducingTime': 'Introducing time',
    'HighestVulnerabilityLevel': 'The highest vulnerability level',
    'RecommendedFixVersion': 'Recommended fix version',
    // ChartArtifactComponent.vue
    'ComponentStatistics': 'Component statistics',
    'ComponentClassification': 'Component classification',
    'TotalNumberOfComponents': 'Total number of components',
    'HealthyComponents': 'Healthy components',
    'RiskComponents': 'Risk components',
    'ComponentInformation': 'Component information',
    'ComponentOverview': 'Component Overview',
    'ScanTime': 'Scan time',
    'exportExcel':'Export excel',
    'exportPdf':'Export PDF',
    'printExportCount':'Please enter export quantity',
    'OK':'OK',
    'CANCEL':'CANCEL',
    'exporting':'E#xporting...',
    'ProductScanDetail':'Product scan detail',
    'ProductScan':'Product scan'
}
