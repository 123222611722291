export default {
    'UserManagement': '用户管理',
    'Adduser': '添加用户',
    'TotalNumberOfUsers': '用户总数',
    'NumberOfAdministrators': '管理员数',
    'NumberOfAnonymousUsers': '匿名用户数',
    'UserList': '用户列表',
    'EnterTheUsernameQuery': '输入用户名查询',
    'EnterTheEmailQuery': '输入邮箱查询',
    'EnterTheNicknameQuery': '输入昵称查询',
    'IsItAvailable': '是否可用',
    'Available': '可用',
    'NotAvailable': '不可用',
    'RoleInformation': '角色信息',
    'InbuiltRoleInformation': '内置角色信息',
    'GroupInformation': '用户组信息',
    'GroupEdit': '用户组编辑',
    'UserInformation': '用户信息',
    'UserEdit': '用户编辑',
    'Cancel': '取消',
    'Save': '保存',
    'UserName': '用户名',
    'EnterYourUsername': '请输入用户名',
    'Password': '密码',
    'PleaseEnterEmail': '请输入邮箱，非必填',
    'ActivatedOrNot': '是否激活',
    'TurnOn': '开启',
    'ShutDown': '关闭',
    'RoleEditing': '内置角色编辑',
    'DeleteConfirmation': '删除确认',
    'SureDeleteUser1': '你确定要删除',
    'SureDeleteUser2': '这个用户么？',
    'Delete': '删除',
    'userNameLength': '长度在 2 到 36 个字符',
    'EnterThePassword': '请输入密码',
    'passwordLength': '长度在 12 到 30 个字符',
    'PleaseSelectTheRole': '请选择角色',
    'DeleteSuccess': '删除成功',
    'PasswordFormat': '密码应为字母，数字，特殊符号(~!@#$%^&*()_.)，三种及以上组合，12-30位字符串，如：example666@abc',
    // Personal.vue
    'PersonalInformation': '个人信息',
    'Email': '邮箱',
    'NewPassword': '新密码',
    'EnterNewPassword': '请输入新密码',
    'EnterYourNewPasswordAgain': '再次输入新密码',
    'EnterNewPasswordAgain': '请再次输入新密码',
    'TwoDifferentPasswordInputs': '两次输入密码不一致',
    'DuplicatePasswordsCannotBeEmpty': '重复密码不能为空',
    'LogInAgain': '个人信息及密码更新成功，请重新登录',
    'InformationUpdated': '个人信息更新成功',
    'FailedUpdateInformation': '更新个人信息失败',
    'EnterEmail': '请输入邮箱',
    'OperateSuccess': '操作成功',
    'PasswordUpdateFailed': '密码更新失败',
    'PasswordUpdateSuccess': '密码更新成功，请重新登录',
    'UserRole':'请选择用户角色',
    'Administrators': '管理员',
    'GeneralUsers': '普通用户',
    'Nickname': '昵称',
    'PleaseEnterNickname': '请输入昵称,非必填',
}
