var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"left_tree-container"},[(_vm.isDragging)?_c('div',{staticClass:"cover-box"}):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.loadingMore}},[_c('div',{ref:"tree_container",staticClass:"tree_container",style:({ height: _vm.topHeight + 'px' }),on:{"scroll":_vm.handleScroll}},[_c('a-tree',{key:_vm.key,ref:"tree",staticClass:"repositoryTree",attrs:{"replaceFields":_vm.replaceFields,"load-data":function (treeNode) { return _vm.onLoadData(treeNode, false); },"tree-data":_vm.treeData,"show-line":true,"expandedKeys":_vm.expandedKeys,"show-icon":"","selectedKeys":_vm.selectedKeys},on:{"select":_vm.treeSelect,"expand":function (expandedKeys,obj) { return _vm.onExpand(expandedKeys, obj, false); },"rightClick":function (e) { return _vm.rightClick(e,'tree'); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var expanded = ref.expanded;
var name = ref.name;
var id = ref.id;
var type = ref.type;
var selected = ref.selected;
var fileType = ref.fileType;
return [_c('div',{staticClass:"title_box"},[(fileType === 'document')?_c('img',{attrs:{"src":_vm.getSrc(selected, type),"alt":"","width":"24"}}):_vm._e(),(fileType === 'document')?_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[(type === 'dir' || type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"10px"},attrs:{"type":_vm.getIconType(name, type)}}),_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(name)+" ")])],1)])]}}])},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"}),_c('a-icon',{attrs:{"slot":"switcherIcon","type":"folder-open"},slot:"switcherIcon"})],1)],1)]),_c('div',{staticClass:"line",class:_vm.isDragging ? 'line-drag' : '',on:{"mousedown":_vm.startDragging}}),_c('a-spin',{attrs:{"spinning":_vm.loadingMore}},[_c('div',{ref:"tree_container",staticClass:"tree_container recycle",style:({ height: _vm.bottomHeight + 'px' }),on:{"scroll":_vm.handleScroll}},[_c('a-tree',{key:_vm.recycleKey,ref:"tree",staticClass:"repositoryTree",attrs:{"replaceFields":_vm.replaceFields,"load-data":function (treeNode) { return _vm.onLoadData(treeNode, true); },"tree-data":_vm.recycleTreeData,"show-line":true,"expandedKeys":_vm.expandedRecycleKeys,"show-icon":"","selectedKeys":_vm.selectRecycleKeys},on:{"select":function (key,e) { return _vm.treeSelect(key,e,true); },"expand":function (expandedKeys,obj) { return _vm.onExpand(expandedKeys,obj, true); },"rightClick":function (e) { return _vm.rightClick(e,'recycleTree'); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var expanded = ref.expanded;
var name = ref.name;
var id = ref.id;
var type = ref.type;
var selected = ref.selected;
var fileType = ref.fileType;
return [_c('div',{staticClass:"title_box"},[(fileType === 'document')?_c('img',{attrs:{"src":_vm.getSrc(selected, type),"alt":"","width":"24"}}):_vm._e(),(fileType === 'document')?_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[(type === 'dir' || type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"8px"},style:(type == 'recycle'? 'color:#393b3e':''),attrs:{"type":_vm.getIconType(name, type)}}),_c('span',{staticClass:"tree_title",style:(type == 'recycle'? 'color:#393b3e':'')},[_vm._v(" "+_vm._s(name)+" ")])],1)])]}}])},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"}),_c('a-icon',{attrs:{"slot":"switcherIcon","type":"folder-open"},slot:"switcherIcon"})],1)],1)]),_c('rightMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContextMenu),expression:"showContextMenu"}],ref:"rightMenu",style:(_vm.contextMenuStyle),attrs:{"folibRepository":_vm.folibRepository,"repositoryType":_vm.repositoryType,"currentFileDetial":_vm.currentFileDetial,"uploadEnabled":_vm.uploadEnabled,"copyEnabled":_vm.copyEnabled,"dispatchEnabled":_vm.dispatchEnabled,"moveEnabled":_vm.moveEnabled,"currentTreeNode":_vm.isTrashView ? _vm.currentTreeNodeRecycle : _vm.currentTreeNode,"isTrashView":_vm.isTrashView},on:{"reload":_vm.reload,"localDelNode":_vm.localDelNode,"handleMenuClick":_vm.handleMenuClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }